import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import 'element-ui/lib/theme-chalk/index.css'
import ElementUI from 'element-ui';

import "swiper/dist/css/swiper.min.css"
import "./assets/css/public.css"
import "./assets/css/style.css"
import 'wowjs/css/libs/animate.css'
// import 'animate.css'
import 'video.js/dist/video-js.css'

Vue.use(ElementUI);

Vue.config.productionTip = false

// 引入dayjs
import dayjs from "dayjs"
Vue.prototype.dayjs = dayjs

import axios from 'axios'
Vue.prototype.$http = axios

// 全局id - 六建
Vue.prototype.webId = 'D0FF2C34-6495-4F08-8B48-0F2CDF2F0F8B'
Vue.prototype.webIdGroup = 'BEE8AB69-65E3-4161-ACF7-0E09E4028BB8'

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')